import * as React from "react"
import PropTypes from "prop-types"
import { useState, useEffect } from "react"
import { Link } from "gatsby"
import "../styles/main.scss"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"

const Header = () => {
  const [offset, adjustOffset] = useState("-270px")
  const [height, setHeight] = useState("125px")
  const [brand, setBrand] = useState("2.5rem")

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (currPos.y < -130 && height == "125px") {
        setHeight("75px")
        setBrand("scale(.7)")
      } else if (currPos.y > -100 && height == "75px") {
        setHeight("125px")
        setBrand("scale(1)")
      }
    },
    [height],
    false,
    false,
    300
  )

  const toggleDrawer = () => {
    if (offset === "0px") {
      adjustOffset("-270px")
    } else {
      adjustOffset("0px")
    }
  }

  return (
    <>
      <header style={{ height: height }}>
        <div className="left">
          <Link to="/">
            <h1
              className="brand"
              style={{ transform: brand }}
              className="brand"
            >
              Michael Pasvar
            </h1>
          </Link>
        </div>
        <div className="right">
          <Link className="navLink" to="/podcast">
            Back in The Day Day
          </Link>
          <Link className="navLink" to="/shows">
            Shows
          </Link>
          <Link className="navLink" to="/juicyjogger">
            Juicy Jogger
          </Link>
          <Link className="navLink" to="/videos">
            Videos
          </Link>
          <Link className="navLink" to="/about">
            About
          </Link>
          <Link className="navLink" to="/epk">
            EPK
          </Link>

          <a target="_blank" href="https://burginton.typeform.com/to/l5BCfnZ3">
            <button style={{ marginLeft: "15px" }} className="outlined">
              Contact
            </button>
          </a>
          <a
            className="navButton"
            target="_blank"
            href="https://michelpasvar.bigcartel.com/"
          >
            <button>Merch</button>
          </a>
        </div>
        <FontAwesomeIcon
          onClick={() => {
            toggleDrawer()
          }}
          className="burger"
          icon={faBars}
        />
      </header>
      <div style={{ marginTop: `${offset}` }} className="drawer">
        <Link
          onClick={() => {
            toggleDrawer()
          }}
          className="mobileLink"
          to="/podcast"
        >
          ➟ Back in The Day Day
        </Link>
        <Link className="mobileLink" to="/shows">
          {" "}
          ➟ Shows
        </Link>
        <Link className="mobileLink" to="/juicyjogger">
          ➟ Juicy Jogger
        </Link>
        <Link className="mobileLink" to="/videos">
          ➟ Videos
        </Link>
        <Link className="mobileLink" to="/about">
          ➟ About
        </Link>
        <Link className="mobileLink" to="/epk">
          ➟ EPK
        </Link>
        <a
          className="mobileLink"
          target="_blank"
          href="https://burginton.typeform.com/to/l5BCfnZ3"
        >
          ➟ Contact
        </a>
        <a
          className="mobileLink"
          target="_blank"
          href="https://michelpasvar.bigcartel.com/"
        >
          ➟ Merch
        </a>
      </div>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
