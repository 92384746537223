import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import Header from "./header"
import "../styles/main.scss"
import footerLogo from "../images/cartoonsketch.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTwitter,
  faFacebook,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"

const Layout = ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query SocialQuery {
        markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
          frontmatter {
            instagram
            twitter
            facebook
            email
            youtube
          }
        }
      }
    `
  )

  return (
    <>
      <div className="stickyHeader">
        <Header />
      </div>
      <div className="mainContainer">
        <main>{children}</main>
        <footer>
          <div className="footer">
            <div className="footerFirst">
              <div className="row">
                <img className="footerLogo" src={footerLogo} />
                <div className="footerSecond">
                  <div className="row">
                    <a
                      target="_blank"
                      href={data.markdownRemark.frontmatter.instagram}
                    >
                      <FontAwesomeIcon
                        className="socialIcons space"
                        icon={faInstagram}
                      />
                    </a>
                    <a
                      target="_blank"
                      href={data.markdownRemark.frontmatter.twitter}
                    >
                      {" "}
                      <FontAwesomeIcon
                        className="socialIcons space"
                        icon={faTwitter}
                      />
                    </a>
                    <a
                      target="_blank"
                      href={data.markdownRemark.frontmatter.facebook}
                    >
                      {" "}
                      <FontAwesomeIcon
                        className="socialIcons space"
                        icon={faFacebook}
                      />
                    </a>
                    <a
                      target="_blank"
                      href={data.markdownRemark.frontmatter.youtube}
                    >
                      {" "}
                      <FontAwesomeIcon
                        className="socialIcons space"
                        icon={faYoutube}
                      />
                    </a>
                  </div>
                  <Link className="footerLink" to="/podcast">
                    Back in The Day Day
                  </Link>
                  <Link className="footerLink" to="/shows">
                    Shows
                  </Link>
                  <Link className="footerLink" to="/juicyjogger">
                    Juicy Jogger
                  </Link>
                  <Link className="footerLink" to="/juicyjogger">
                    Videos
                  </Link>
                  <Link className="footerLink" to="/about">
                    About
                  </Link>
                  <Link className="footerLink" to="/epk">
                    EPK
                  </Link>
                </div>
              </div>
              <div className="footerThird">
                <h3 style={{ margin: "0px", color: "white" }}>Contact</h3>
                <a
                  target="_blank"
                  href="https://burginton.typeform.com/to/l5BCfnZ3"
                >
                  ➟ BOOK
                </a>
                <a target="_blank" href="https://michelpasvar.bigcartel.com/">
                  ➟ MERCH
                </a>
                <a
                  target="_blank"
                  href="https://dedicated-founder-2746.ck.page/02a94ba0d8"
                >
                  ➟ SUBSCRIBE
                </a>
              </div>
            </div>
          </div>
          <p
            style={{
              textAlign: "center",
              color: "gray",
              margin: "0px",
              fontSize: ".7rem",
            }}
            className="copyright"
          >
            {" "}
            Copyright © 2021 Michael Pasvar.{" "}
          </p>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
